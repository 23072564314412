import { MdSend } from 'react-icons/md'

import apolloClient from '../../apollo-client'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import TextField from '@mui/material/TextField'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { SEND_EMAIL_MUTATION } from '../../graphql/email'
import { getStatesArr } from '../../lib/Utils'
import MultiSelect from '../ui/input/MultiSelect'
import Box from '@mui/material/Box'
import CustomizedModal from './CustomizedModal'
import GlobalContext from '../../contexts/GlobalContext'
import SingleSelect from '../ui/input/SingleSelect'

interface Props {
  open: boolean
  handleClose: (v: boolean) => void
}
type FormValues = {
  firstAndLastName: string
  companyName: string
  email: string
  phone: string
  stateOfOperation: string[]
  hiringPositions: string[]
  trailerTypes: string[]
  endorsements: string[]
  dotNumber: string
  fleetSize: string
}

const states = getStatesArr()

const schema = yup
  .object({
    firstAndLastName: yup.string().label('First/Last').required(),
    companyName: yup.string().label('Company Name').required(),
    email: yup.string().label('Email').required().email(),
    phone: yup
      .string()
      .label('Phone Number')
      .required()
      .matches(
        new RegExp('^(0|[1-9][0-9]*){10,}$'),
        'Please enter a valid 10 digit phone number'
      ),
    dotNumber: yup.string().label('DOT Number').required(),
    fleetSize: yup.string().label('Fleet Size').required(),
    stateOfOperation: yup
      .array()
      .min(1)
      .of(yup.string())
      .required()
      .label('State Of Operation'),
    hiringPositions: yup
      .array()
      .min(1)
      .of(yup.string())
      .required()
      .label('Hiring Positions')
  })
  .required()

export default function AddCompany({ open, handleClose }: Props) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setErrorMsg } = useContext(GlobalContext)

  const {
    control,
    formState: { errors, isValid },
    getValues
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      firstAndLastName: '',
      companyName: '',
      email: '',
      phone: ''
    }
  })
  const Label = (text: string) => {
    return <label className="text-[#0000008a]">{text}</label>
  }
  const btnIsDisabled = loading || !isValid

  const onSubmit = async () => {
    if (btnIsDisabled) {
      return
    }
    try {
      const values = getValues()

      setLoading(true)

      let body = ''
      body += `Name: ${values.firstAndLastName} \r<br/>`
      body += `Company Name: ${values.companyName} \r<br/>`
      body += `Phone: ${values.phone} \r<br/>`
      body += `Email: ${values.email} \r<br/>`
      body += `DOT #: ${values.dotNumber} \r<br/>`
      body += `Fleet Size: ${values.fleetSize} \r<br/>`
      body += `States of Operation: ${values.stateOfOperation.join(
        ', '
      )} \r<br/>`
      body += `Endorsements: ${values.endorsements.join(', ')} \r<br/>`
      body += `Hiring Positions: ${values.hiringPositions.join(', ')} \r<br/>`
      body += `Trailer Types: ${values.trailerTypes.join(', ')} \r<br/>`

      const { data } = await apolloClient.mutate({
        mutation: SEND_EMAIL_MUTATION,
        variables: {
          to: 'info@driversjobchoice.com',
          from: values.email,
          subject: 'ADD YOUR COMPANY',
          body: body
        }
      })
      if (data.sendEmail) {
        setShowSuccess(true)
      }
    } catch (e) {
      setErrorMsg('Uh-oh! Error adding new company. Try again soon.')
    }
    setLoading(false)
  }

  return (
    <CustomizedModal
      loadMask={loading}
      footer={
        showSuccess
          ? false
          : [
              <button
                key="submit"
                type="button"
                disabled={btnIsDisabled}
                className="disabled:bg-[#888] w-full text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={onSubmit}>
                <div className="flex items-center content-center justify-center text-[16px] font-bold font-arial">
                  <span>Submit</span>
                  <MdSend className="ml-2" />
                </div>
              </button>
            ]
      }
      open={open}
      onCancel={() => handleClose(false)}>
      <H1>ADD YOUR COMPANY</H1>

      {!showSuccess ? (
        <Box
          component="form"
          sx={{ '& > :not(style)': { mb: 1 } }}
          noValidate
          autoComplete="off">
          <Controller
            control={control}
            name="firstAndLastName"
            render={({ field }) => (
              <TextField
                {...field}
                required
                label={Label('First/Last Name')}
                error={!!errors.firstAndLastName}
                helperText={errors?.firstAndLastName?.message || ' '}
                fullWidth={true}
                variant="standard"
              />
            )}></Controller>
          <Controller
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label={Label('Company Name')}
                error={!!errors.companyName}
                helperText={errors?.companyName?.message || ' '}
                fullWidth={true}
                variant="standard"
              />
            )}
            name="companyName"></Controller>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                required
                label={Label('Email')}
                error={!!errors.email}
                helperText={errors?.email?.message || ' '}
                fullWidth={true}
                variant="standard"
              />
            )}></Controller>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <TextField
                {...field}
                required
                label={Label('Phone')}
                error={!!errors.phone}
                helperText={errors?.phone?.message || ' '}
                fullWidth={true}
                variant="standard"
              />
            )}></Controller>
          <Controller
            control={control}
            name="dotNumber"
            render={({ field }) => (
              <TextField
                {...field}
                required
                label={Label('DOT #')}
                error={!!errors.phone}
                helperText={errors?.dotNumber?.message || ' '}
                fullWidth={true}
                variant="standard"
              />
            )}></Controller>
          <SingleSelect
            control={control}
            required={true}
            error={!!errors.fleetSize}
            controlName="fleetSize"
            label="What is your fleet size?"
            fieldNames={{
              fieldLabel: 'title',
              fieldValue: 'title'
            }}
            vocabularyKey="fleet_size"
          />

          <MultiSelect
            control={control}
            required={true}
            error={!!errors.stateOfOperation}
            controlName="stateOfOperation"
            placeholder="State Of Operation"
            label="State Of Operation"
            fieldNames={{
              fieldLabel: 'label',
              fieldValue: 'label'
            }}
            staticData={states}
          />
          <MultiSelect
            control={control}
            required={true}
            error={!!errors.hiringPositions}
            controlName="hiringPositions"
            placeholder="Hiring Positions"
            label="Hiring Positions"
            fieldNames={{
              fieldLabel: 'title',
              fieldValue: 'title'
            }}
            vocabularyKey="position_available"
          />
          <MultiSelect
            control={control}
            error={!!errors.trailerTypes}
            controlName="trailerTypes"
            placeholder="Trailer Types"
            label="Trailer Types"
            fieldNames={{
              fieldLabel: 'title',
              fieldValue: 'title'
            }}
            vocabularyKey="trailer_types"
          />
          <MultiSelect
            control={control}
            error={!!errors.endorsements}
            controlName="endorsements"
            label="Endorsements"
            fieldNames={{
              fieldLabel: 'title',
              fieldValue: 'title'
            }}
            placeholder="Endorsements"
            vocabularyKey="endorsements"
          />
        </Box>
      ) : (
        <div className="text-center text-[16px] text-[#6f6f6f] font-lato-light font-semibold">
          Thank you for your interest. A representative of driversjobchoice.com
          will contact you shortly.
        </div>
      )}
    </CustomizedModal>
  )
}
const H1 = styled.h1`
  color: #444;
  font-size: 26px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  display: block;
`
